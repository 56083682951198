import { useEffect, useState } from "react";
import homeSectionData from '../../assets/json/homePageData.json';
import researchData from "../../assets/json/researchData.json";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Common from "../Common";

function Blogs() {
  const [blogData, setBlogData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("--All Topics--");
  const cardsPerPage = 6;

  const getUsersData = async () => {
    try {
      const response = await axios.get(
        "https://www.feedrapp.info/api/?q=https://blog.tursio.ai/feed/&num=100"
      );
      setBlogData(response.data.responseData.feed.entries);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCardClick = (link) => {
    window.open(link, '_blank');
  };

  useEffect(() => {
    getUsersData();
    setCurrentPage(1);
  }, []);

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;

  const items = blogData
    ?.sort((a, b) => new Date(b.publishedDate) - new Date(a.publishedDate))
    .map((item) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(item.content, "text/html");
      const paragraphs = Array.from(doc.querySelectorAll("p"));
      const description = paragraphs.length > 0
        ? paragraphs.map(p => p.textContent).join(" ").substring(0, 200)
        : "";

      return {
        author: item?.author,
        pubDate: item?.publishedDate
          ? new Date(item.publishedDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : "",
        title: item?.title,
        link: item?.link,
        description: description + "...",
        imgUrl: doc.querySelector("figure>img")?.src,
        categories: item?.categories.map(category => category.name)
      };
    });

  const categories = ["--All Topics--", ...new Set(items?.flatMap(item => item.categories))];

  const filteredItems = items?.filter(item => 
    (selectedCategory === "--All Topics--" || item.categories.includes(selectedCategory)) &&
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentCards = filteredItems?.slice(indexOfFirstCard, indexOfLastCard);
  const totalPages = Math.ceil(filteredItems?.length / cardsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="blog-page">
      <Common />
      <div className="blog-header-section">
        <div className="container">
          <div className="blog-search-header">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="theme-title">
                  <h3 className="title-left">
                    <span>Tursionic Blog</span>
                  </h3>
                </div>
              </div>

              <div className="col-lg-9 col-md-12 col-sm-12">
                <div className="blog-inner-container">
                  <div className="blog-search">
                    <img
                      src={`${process.env.PUBLIC_URL}/image/${researchData?.images?.searchIconFileName}.svg`}
                      alt=""
                    />
                    <input
                      type="search"
                      placeholder="Search"
                      onChange={(e) => {setSearchTerm(e.target.value); setCurrentPage(1);}}
                    />
                  </div>
                  <div className="blog-dropdown">
                    <div className="dropdown">
                      <select
                        className="blog-btn w-100"
                        onChange={(e) => {setSelectedCategory(e.target.value); setCurrentPage(1);}}
                      >
                        {/* <option key={0} value="">
                          --All Topics--
                        </option> */}
                        {categories.map((ele, ind) => {
                          return (
                            <option key={ind} value={ele}>
                              {ele}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
          </div>
        </div>
      </div>

      <section className="blog-body-section">
        <div className="container">
          <div className="d-flex">
            <div className="blog-nav-body">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade active show"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="row">
                    {currentCards?.map((item, index) => {
                      return (
                        <div
                          className="col-lg-4 col-md-6 col-sm-12 mb-3"
                          key={index}
                        >
                          <div className="blog-tab-card" onClick={() => handleCardClick(item.link)}>
                            <img
                              className="w-100"
                              src={item?.imgUrl}
                              alt=""
                            />
                            <div className="blog-contant">
                              <div className="blog-date">
                                <span>{item?.author}</span>
                                <span>{item?.pubDate}</span>
                                <span>Published in Tursionic</span>
                              </div>
                              <div className="blog-title">
                                <h3>{item?.title}</h3>
                              </div>
                              <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="pagination-body research-pagination">
              <nav aria-label="Page navigation example">
                {totalPages !== 0 && (<ul className="pagination justify-content-center">
                  <li
                    className={`page ${currentPage === 1 ? "disabled" : ""}`}
                  >
                    <button
                      className="page-link me-4 previous-next-btn"
                      onClick={() => paginate(currentPage - 1)}
                    >
                      {" "}
                      <img src="image/pagination-left-arrow.svg" alt="" />
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li
                      key={index}
                      className={`page ${index + 1 === currentPage ? "active" : ""}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page ${currentPage === totalPages ? "disabled" : ""}`}
                  >
                    <button
                      className="page-link ms-4 previous-next-btn"
                      onClick={() => paginate(currentPage + 1)}
                    >
                      {" "}
                      Next{" "}
                      <img
                        src="image/pagination-right-arrow.svg"
                        alt=""
                        className=""
                      />
                    </button>
                  </li>
                </ul>)}
              </nav>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Blogs;
