import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Papa from "papaparse";

function CreateAccount() {
  const [accountName, setAccountName] = useState("");
  const [error, setError] = useState("");
  const [accountMapping, setAccountMapping] = useState([]);

  // Fetch and parse the CSV on component mount
  useEffect(() => {
    Papa.parse('/csv/AccountMapping.csv', {
      download: true,
      header: true,
      complete: (results) => {
        setAccountMapping(results.data);
      },
      error: (err) => {
        console.error("Error while parsing CSV:", err);
      },
    });
  }, []);

  const handleSubmit = () => {
    setError("");

    // Basic empty validation
    if (!accountName.trim()) {
      setError("Please enter account name.");
      return;
    }

    // Case-insensitive check in the CSV data
    const found = accountMapping.find(
      (row) => row["Account Name"]?.toLowerCase() === accountName.toLowerCase()
    );

    if (found) {
      // Redirect on the same tab
      window.location.href = found["Account Link"];
    } else {
      setError("Account not found. Please try again or contact Tursio for onboarding.");
    }
  };

  return (
    <div className="create-store-body">
      <img className="half-logo" src="image/half-logo.png" alt="logo" />

      <div className="container create-store-container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-7 col-md-12 col-sm-12 mb-4">
            <div style={{ marginBottom: "1rem" }}>
                <h2 style={{ margin: 0, fontSize: "2rem", paddingLeft: "30px", paddingBottom: "40px"}}>
                  <span style={{ color: "#212529" }}>Why</span>{" "}
                  <span style={{ color: "#398FFF" }}>Tursio?</span>
                </h2>
              </div>

            <div>
              <div className="create-account-tab">
                <div className="security-img">
                  <img src="image/Accuracy.png" alt="logo" />
                </div>
                <div className="account">
                  <h4>Accuracy</h4>
                  <p>Answer natural language questions on structured data accurately.</p>
                </div>
              </div>
              <div className="create-account-tab security-tab">
                <div className="security-img">
                  <img src="image/Security.png" alt="logo" />
                </div>
                <div className="account">
                  <h4>Security</h4>
                  <p>Operate within your environment with zero data movement.</p>
                </div>
              </div>
              <div className="create-account-tab">
                <div className="security-img">
                  <img src="image/Speed.png" alt="logo" />
                </div>
                <div className="account">
                  <h4>Speed</h4>
                  <p>Cut down the time to deploy AI with no new infrastructure.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-12 col-sm-12 mb-4">
            <div className="account-form">
              <label>Account Name</label>
              <input
                placeholder="Enter Your Account Name"
                value={accountName}
                onChange={(e) => {
                  setAccountName(e.target.value);
                  setError("");
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              />
              <button onClick={handleSubmit}>SUBMIT</button>
              <div className="error-message">
                {error && <p style={{ color: "white", margin: 0 }}>{error}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hexagon-section">
        <img className="hexagon-1" src="image/hexagon-1.png" alt="logo" />
        <img className="hexagon-2" src="image/hexagon-2.svg" alt="logo" />
        <img className="hexagon-3" src="image/hexagon-3.svg" alt="logo" />
      </div>
    </div>
  );
}

export default CreateAccount;
