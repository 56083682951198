import { useNavigate } from "react-router-dom";
import aboutPageData from "../../assets/json/aboutData.json";
import Common from "../Common";

function About() {
  const navigate = useNavigate();

  const handleClick = () => {
      navigate('/contact');
  };

  return (
    <div className="about-page">
      <Common />
      <section className="about-slider">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12 mb-4">
              <div className="header-theme-title h-100">
                <h1>
                  {aboutPageData?.AboutBanner?.title}{" "}
                  <span>{aboutPageData?.AboutBanner?.subTitleOne}</span>
                  {aboutPageData?.AboutBanner?.subTitleTwo}
                </h1>
                <p>{aboutPageData?.AboutBanner?.content}</p>
                <div className="d-inline-block w-100 mt-4">
                  <button type="button" className="btn btn-primary me-2" onClick={handleClick}>
                    {aboutPageData?.AboutBanner?.requestDemoBtn}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="about-slide-cube">
                <img
                  className="w-100 about-banner-shape"
                  src={`${process.env.PUBLIC_URL}/image/${aboutPageData?.AboutBanner?.imageFileName}.svg`}
                  alt="About Banner"
                />
                <img className="about-cube-one" alt="Banner Shape" src="image/about-cube.svg" />
                <img className="about-cube-tow" alt="Banner Shape" src="image/about-cube.svg" />
                <img className="about-cube-three" alt="Banner Shape" src="image/about-cube.svg" />
              </div>
            </div>
          </div>
        </div>
        <img className="about-shap-two-cube" alt="Banner Shape" src="image/about-banner-shap-two.svg" />
      </section>

      <section className="our-story-section" id="story">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="theme-title">
                <label>{aboutPageData?.OurStory?.label}</label>
                <h2>
                  {aboutPageData?.OurStory?.title}{" "}
                  <span>{aboutPageData?.OurStory?.subTitle}</span>
                </h2>
                <p>{aboutPageData?.OurStory?.contentOne}</p>
                <p>{aboutPageData?.OurStory?.contentTwo}</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              {aboutPageData?.OurStory?.journey?.map((ele, ind) => (
                <div className="our-story-date-card" key={ind}>
                  <div className="date-card">
                    <div className="left-icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/image/${ele.imageFileName}.svg`}
                        alt="Journey Icon"
                      />
                    </div>
                    <div className="date-content">
                      <h6>
                        <span>{ele?.year}</span> {ele?.month}
                      </h6>
                      <h4>{ele?.title}</h4>
                      <p>{ele?.content}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="meet-our-section" id="leaders">
        <div className="container">
          <div>
            <div className="theme-title">
              <h2>
                {aboutPageData?.LeadersData?.title}{" "}
                <span>{aboutPageData?.LeadersData?.subTitle}</span>
              </h2>
            </div>
            <div className="row">
              {aboutPageData?.LeadersData?.ProfileCard?.map((leader, ind) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                  key={ind}
                >
                  <div className="meet-our-card">
                    <div className="user-image">
                      <img
                        src={`${process.env.PUBLIC_URL}/image/${leader?.imageFileName}.png`}
                        alt={`${leader?.name}'s Photo`}
                      />
                    </div>
                    <div className="user-name">
                      <div className="text-start">
                        <h4>{leader?.name}</h4>
                        <p>{leader?.position}</p>
                      </div>
                      <div>
                        <a href={leader?.linkedinUrl} target="_blank" rel="noopener noreferrer">
                          <img
                            src={`${process.env.PUBLIC_URL}/image/${leader?.logoFileName}.svg`}
                            alt="Leader Logo"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <div className="team-tursio-section">
        <div className="container">
          <div className="team-tursio-bg">
            <h2>{aboutPageData?.TeamTursio?.title}</h2>
          </div>
        </div>
      </div>

      <section className="join-tursio-section">
        <img
          alt="Join Shape One"
          className="join-cube-one"
          src="image/join-tursio-bg-shape-one.svg"
        />
        <div className="container position-relative">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="theme-title">
                <label>
                  <b>{aboutPageData?.JoinTursio?.title}</b>
                  {aboutPageData?.JoinTursio?.subTitle}
                </label>
                <h2>{aboutPageData?.JoinTursio?.contentOne}</h2>
                <p>{aboutPageData?.JoinTursio?.contentTwo}</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 join-btn">
              <div>
                <button type="button" className="btn btn-primary ms-3" onClick={handleClick}>
                  {aboutPageData?.JoinTursio?.ContactTursioBtn}
                </button>
              </div>
            </div>
          </div>
        </div>
        <img
          alt="Join Shape Two"
          className="join-cube-tow"
          src="image/join-tursio-bg-shape-two.svg"
        />
      </section>
    </div>
  );
}

export default About;
