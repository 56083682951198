import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Calendar, Clock, CheckCircle } from 'lucide-react';
import './WebinarDetail.scss';
import webinarData from '../../assets/json/webinar.json';

const WebinarDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  
  // Find the webinar that matches the ID from the URL
  // Note: This assumes webinar.json has been updated to the expanded format
  // with multiple webinars in an array
  const webinar = webinarData.webinar?.find(w => w.id === id) || null;

  useEffect(() => {
    if (!webinar || !webinar.webinarDate) {
      return;
    }
    
    const webinarDate = new Date(webinar.webinarDate);
    
    const updateCountdown = () => {
      const now = new Date();
      const difference = webinarDate - now;
      
      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        
        setCountdown({ days, hours, minutes, seconds });
      }
    };
    
    // Update countdown immediately
    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [webinar]);

  if (!webinar) {
    return (
      <div className="container mt-5 pt-5 text-center">
        <h2>Webinar not found</h2>
        <p>The webinar you're looking for doesn't exist or has been removed.</p>
        <button 
          className="btn btn-primary mt-3" 
          onClick={() => navigate('/webinars')}
        >
          View All Webinars
        </button>
      </div>
    );
  }

  return (
    <div className="webinar-detail-component">
      <div className="webinar-page">
        <section className="hero-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className='webinar-content'>
                  <div className="webinar-badge">{webinar.badge}</div>
                  <h1 className="webinar-title">
                    <span className="dark-text">{webinar.title.firstPart} </span> 
                    <span className="blue-text">{webinar.title.secondPart}</span>
                  </h1>
                  
                  <div className="webinar-meta">
                    <div className="date-time">
                      <div className="date">
                        <Calendar size={18} className="icon" />
                        <span>{webinar.date}</span>
                      </div>
                      <div className="time">
                        <Clock size={18} className="icon" />
                        <span>{webinar.time}</span>
                      </div>
                    </div>
                  </div>
                  
                  <p className="webinar-description">
                    {webinar.description}
                  </p>
                  
                  <div className="cta-buttons">
                    <a href={webinar.registrationLink} className="btn btn-primary">Register Now</a>
                    <a href="/contact" className="btn btn-outline">Request Demo</a>
                  </div>
                </div>
              </div>
              
              <div className="countdown-container col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="countdown-wrapper">
                  <h3>Webinar Starts In</h3>
                  <div className="countdown">
                    <div className="countdown-item">
                      <div className="count">{String(countdown.days).padStart(2, '0')}</div>
                      <div className="label">Days</div>
                    </div>
                    <div className="countdown-item">
                      <div className="count">{String(countdown.hours).padStart(2, '0')}</div>
                      <div className="label">Hours</div>
                    </div>
                    <div className="countdown-item">
                      <div className="count">{String(countdown.minutes).padStart(2, '0')}</div>
                      <div className="label">Minutes</div>
                    </div>
                    <div className="countdown-item">
                      <div className="count">{String(countdown.seconds).padStart(2, '0')}</div>
                      <div className="label">Seconds</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* About Section */}
        <section id="about" className="about-section">
          <div className="container">
            <div className="section-header">
              <div className="badge">{webinar.about.badge}</div>
              <h2>{webinar.about.title}</h2>
              <p>
                {webinar.about.description}
              </p>
            </div>
            
            <div className="content-grid">
              <div className="why-attend">
                <h3>{webinar.whyAttend.title}</h3>
                <p>
                  {webinar.whyAttend.description}
                </p>
                <ul className="feature-list">
                  {webinar.whyAttend.features.map((feature, index) => (
                    <li key={index}>
                      <CheckCircle size={18} className="feature-icon" /> {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Speakers Section */}
        <section id="speakers" className="speakers-section">
          <div className="container">
            <div className="section-header">
              <div className="badge">{webinar.speakers.badge}</div>
              <h2>{webinar.speakers.title}</h2>
            </div>
            
            <div className="speakers-grid">
              {webinar.speakers.experts.map((speaker, index) => (
                <div className="speaker-card" key={index}>
                  <div className="speaker-image-container">
                    <div className="speaker-image">
                      <img src={speaker.image} alt={speaker.name} />
                    </div>
                  </div>
                  
                  <div className="speaker-info">
                    <h3>{speaker.name}</h3>
                    <p className="role">{speaker.role}</p>
                    <p className="bio">
                      {speaker.bio}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Registration Section */}
        <section id="register" className="register-section">
          <div className="container">
            <div className="register-card">
              <div className="register-header">
                <div className="badge">{webinar.register.badge}</div>
                <h2>{webinar.register.title}</h2>
                <p>
                  {webinar.register.description}
                </p>
              </div>
              
              <div className="registration-content">
                <div className="event-details">
                  <div className="detail-item">
                    <div className="icon-container">
                      <Calendar size={22} />
                    </div>
                    <div className="detail-content">
                      <h4>Date</h4>
                      <p>{webinar.date}</p>
                    </div>
                  </div>
                  
                  <div className="detail-item">
                    <div className="icon-container">
                      <Clock size={22} />
                    </div>
                    <div className="detail-content">
                      <h4>Time</h4>
                      <p>{webinar.time}</p>
                    </div>
                  </div>
                </div>
                
                <div className="learning-points">
                  <h4>What You'll Learn:</h4>
                  <ul>
                    {webinar.register.learningPoints.map((point, index) => (
                      <li key={index}>
                        <div className="icon-wrapper">
                          <CheckCircle size={18} className="feature-icon" />
                        </div>
                        <span>{point}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              
              <div className="cta-container">
                <a href={webinar.registrationLink} className="register-btn">Register Now</a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default WebinarDetail;