import React from "react";

function SolutionsCard({ icon, title, description, points = [] }) {
  return (
    <div className="custom-card flex-fill text-start">
      {/* Icon */}
      <div className="icon-wrapper">
      <img
            id={icon}
            className="solution-icon"
            src={`${process.env.PUBLIC_URL}/image/${icon}.svg`}
            alt=""
        />
      </div>

      {/* Title and Description */}
      <h5 className="card-title">{title}</h5>
      <p className="card-description">{description}</p>

      {/* Bullet Points */}
      {points.length > 0 && (
        <ul className="card-bullet-points">
          {points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SolutionsCard;
