import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import webinarsData from "../../assets/json/webinars.json";
import "./Webinars.scss";
import Common from "../Common";

function Webinars() {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSpeaker, setSelectedSpeaker] = useState("");
  const navigate = useNavigate();
  
  const cardsPerPage = 6;

  const processWebinars = () => {
    if (!webinarsData || !webinarsData.Data || !Array.isArray(webinarsData.Data)) {
      return [];
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to start of day for accurate comparison

    return webinarsData.Data
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .map((webinar) => {
        const speakerNames = webinar.speakers.map(speaker => speaker.name).join(", ");
        
        let formattedDate = "";
        let formattedTime = "";
        let webinarStatus = "";
        
        if (webinar.date) {
          const dateObj = new Date(webinar.date);
          const webinarDay = new Date(dateObj);
          webinarDay.setHours(0, 0, 0, 0); // Set to start of day
          
          // Determine webinar status
          if (webinarDay > today) {
            webinarStatus = "Upcoming";
          } else if (webinarDay.getTime() === today.getTime()) {
            webinarStatus = "Live";
          } else {
            webinarStatus = "Past";
          }
          
          formattedDate = dateObj.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          
          formattedTime = dateObj.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          });
        }
        
        return {
          id: webinar.id,
          speakers: speakerNames,
          speakersArray: webinar.speakers.map(speaker => speaker.name),
          date: formattedDate,
          time: formattedTime,
          timezone: webinar.timezone || "",
          title: webinar.title,
          link: `/webinars/${webinar.id}`,
          description: webinar.description,
          status: webinarStatus
        };
      });
  };

  // Get all webinars
  const items = processWebinars();
  
  // Extract unique speaker names for the dropdown
  const getSpeakerNames = () => {
    const speakerNames = [];
    
    items.forEach(item => {
      if (item.speakersArray) {
        item.speakersArray.forEach(speaker => {
          if (!speakerNames.includes(speaker)) {
            speakerNames.push(speaker);
          }
        });
      }
    });
    
    return speakerNames.sort((a, b) => a.localeCompare(b));
  };
  
  const speakerNames = getSpeakerNames();
  
  // Filter webinars based on search and selected speaker
  const filteredItems = items.filter(item => {
    return (
      (selectedSpeaker === "" 
        ? item 
        : item.speakers.toLowerCase().includes(selectedSpeaker.toLowerCase())
      ) &&
      (search.toLowerCase() === ""
        ? item
        : item.title.toLowerCase().includes(search.toLowerCase()))
    );
  });
  
  // Get current page of webinars
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = filteredItems.slice(indexOfFirstCard, indexOfLastCard);
  const totalPages = Math.ceil(filteredItems.length / cardsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const sortDataBySpeakerName = (value) => {
    setSelectedSpeaker(value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleCardClick = (link) => {
    navigate(link);
  };

  return (
    <div className="webinars-page">
      <Common />
      <div className="webinars-card-section">
        <div className="container">
          <div className="webinars-search-header">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="theme-title">
                  <h3 className="title-left">
                    <span>Webinars</span>
                  </h3>
                </div>
              </div>

              <div className="col-lg-9 col-md-12 col-sm-12">
                <div className="webinars-inner-container">
                  <div className="webinars-search">
                    <img
                      src={`${process.env.PUBLIC_URL}/image/search.svg`}
                      alt=""
                    />
                    <input
                      type="search"
                      placeholder="Search"
                      onChange={(e) => {setSearch(e.target.value); setCurrentPage(1);}}
                    />
                  </div>
                  <div className="webinars-dropdown">
                    <div className="dropdown">
                      <select
                        className="webinars-btn w-100"
                        onChange={(e) => sortDataBySpeakerName(e.target.value)}
                      >
                        <option key={-1} value="">
                          --All Speakers--
                        </option>
                        {speakerNames.map((speaker, ind) => (
                          <option key={ind} value={speaker}>
                            {speaker}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row">
            {currentCards.length > 0 ? (
              currentCards.map((webinar, index) => (
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4" key={index}>
                  <div
                    className="webinar-card"
                    onClick={() => handleCardClick(webinar.link)}
                  >
                    {/* Conditionally display badge based on webinar status */}
                    <div className={`webinar-badge ${webinar.status.toLowerCase()}`}>
                      {webinar.status}
                    </div>
                    <h3>{webinar.title}</h3>
                    <p className="webinar-speakers">Presented by: {webinar.speakers}</p>
                    <p className="webinar-datetime">
                      <span className="webinar-date">{webinar.date}</span> • 
                      <span className="webinar-time">{webinar.time} {webinar.timezone}</span>
                    </p>
                    <p className="webinar-description">{webinar.description}</p>
                    <Link to={webinar.link}>
                      <button className="read-more-btn">Learn More</button>
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center py-5">
                <h4>No webinars available</h4>
                <p>Try adjusting your search or filter options</p>
              </div>
            )}
          </div>
          
          {currentCards.length > 0 && totalPages > 1 && (
            <div className="webinars-pagination">
              <nav aria-label="Webinar pagination">
                <ul className="pagination justify-content-center">
                  <li className={`page ${currentPage === 1 ? "disabled" : ""}`}>
                    <button
                      className="page-link me-4 previous-next-btn"
                      onClick={() => paginate(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      <img src="/image/pagination-left-arrow.svg" alt="" />
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li
                      key={index}
                      className={`page ${index + 1 === currentPage ? "active" : ""}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page ${currentPage === totalPages ? "disabled" : ""}`}
                  >
                    <button
                      className="page-link ms-4 previous-next-btn"
                      onClick={() => paginate(currentPage + 1)}
                    >
                      Next
                      <img
                        src="/image/pagination-right-arrow.svg"
                        alt=""
                        className=""
                      />
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Webinars;