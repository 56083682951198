import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import data from "../../assets/json/researchData.json";
import Common from "../Common";

function Research() {
  const [search, setSearch] = useState("");
  const [researchData, setResearchData] = useState(data);
  const [selectedAuthorName, setSelectedAuthorName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 18;

  const authorsData = [];

  const getauthorsName = () => {
    researchData.Data.filter((ele) =>
      ele.Description.split(",").map((element) => {
        authorsData.push(element);
      })
    );
  };
      
  const navigate = useNavigate();

  const handleClick = () => {
      navigate('/contact');
  };

  getauthorsName();
  let authorsName = authorsData
    .filter((item, index) => authorsData.indexOf(item) === index)
    .sort((a, b) => (a < b ? -1 : 1));

  const sortDataByAuthorName = (e) => {
    setSelectedAuthorName(e);
  };

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const filteredItems = researchData.Data.filter((item) => {
    return (
      (selectedAuthorName === ""
        ? item
        : item.Description.toLowerCase().includes(
            selectedAuthorName.toLowerCase()
          )) &&
      (search.toLowerCase() === ""
        ? item
        : item.Title.toLowerCase().includes(search.toLowerCase()))
    );
  })
  const currentCards = filteredItems.slice(indexOfFirstCard, indexOfLastCard);

  // Function to calculate total pages
  const totalPages = Math.ceil(filteredItems?.length / cardsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleCardClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="research-page">
      <Common />
      <div className="research-card-section">
        <div className="container">
          <div className="research-search-header">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="theme-title">
                  <h3 className="title-left">
                    <span>Research</span>
                  </h3>
                </div>
              </div>

              <div className="col-lg-9 col-md-12 col-sm-12">
                <div className="research-inner-container">
                  <div className="research-search">
                    <img
                      src={`${process.env.PUBLIC_URL}/image/${researchData?.images?.searchIconFileName}.svg`}
                      alt=""
                    />
                    <input
                      type="search"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <div className="research-dropdown">
                    <div className="dropdown">
                      <select
                        className="authorsName research-btn w-100"
                        onChange={(e) => sortDataByAuthorName(e.target.value)}
                      >
                        <option key={-1} value="">
                          --All Author--
                        </option>
                        {authorsName.map((ele, ind) => {
                          return (
                            <option key={ind} value={ele}>
                              {ele}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {currentCards.map((res, index) => (
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3"
                key={index}
              >
                <div
                  className="research-card"
                  onClick={() => handleCardClick(res?.link)}
                  style={{ cursor: "pointer" }}
                >
                  <h3>{res.Title}</h3>
                  <p>{res.Description}</p>
                  <div className="research-contant">
                    <img
                      src={`${process.env.PUBLIC_URL}/image/${researchData?.images?.bookIconFileName}.svg`}
                      alt="book icon"
                    />
                    <b className="active">{res.PublicationYear}</b>
                    <b>{res.location}</b>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="research-pagination">
              <nav aria-label="Page navigation example">
                {totalPages!==0 && (<ul className="pagination justify-content-center">
                  <li className={`page ${currentPage === 1 ? "disabled" : ""}`}>
                    <button
                      className="page-link me-4 previous-next-btn"
                      onClick={() => paginate(currentPage - 1)}
                    >
                      <img src="image/pagination-left-arrow.svg" alt="" />
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li
                      key={index}
                      className={`page ${
                        index + 1 === currentPage ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link ms-4 previous-next-btn"
                      onClick={() => paginate(currentPage + 1)}
                    >
                      Next
                      <img
                        src="image/pagination-right-arrow.svg"
                        alt=""
                        className=""
                      />
                    </button>
                  </li>
                </ul>)}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Research;
