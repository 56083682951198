import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/scss/style.scss';
import Header from './view/common/Header';
import Footer from './view/common/Footer';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './view/Home/Home';
import Customer from './view/customer/Customer';
import Research from './view/research/Research';
import Blog from './view/blog/Blog';
import News from './view/news/News';
import NewsDetail from './view/news/NewsDetail';
import About from './view/about/About';
import Common from './view/Common';
import ContactUs from './view/contactUs/ContactUs';
import TermsAndConditions from './view/terms-conditions/TermsAndConditions';
import PrivacyPolicy from './view/privacy-policy/privacyPolicy';
import ScrollToTop from './ScrollToTop';
import CreateAccount from "./view/create-account/createAccount";
import Solutions from './view/Solutions/Solutions';
import CTASection from './view/cta/CTASection';
import Webinars from './view/webinars/Webinars';
import WebinarDetail from './view/webinars/WebinarDetail';


function App() {
    const location = useLocation();
    const hideHeaderFooterRoutes = ["/login"];
    const shouldHideHeaderFooter = hideHeaderFooterRoutes.includes(location.pathname);

  return (
    <div className="App">
        <ScrollToTop />
        {!shouldHideHeaderFooter && <Common />}
        {!shouldHideHeaderFooter && <Header />}
      <Routes>
      <Route path='/' element={<Home />} />
            <Route path='/contact' element={<ContactUs />} />
            <Route path='/customer' element={<Customer />} />
            <Route path='/research' element={<Research />} />
            <Route path='/blogs' element={<Blog />} />
            <Route path='/solutions' element={<Solutions />} />
            <Route path='/news' element={<News />} />
            <Route path='/news/:id' element={<NewsDetail />} /> {/* New Route */}
            <Route path='/about' element={<About />} />
            <Route path='/terms-conditions' element={<TermsAndConditions />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/portal' element={<CreateAccount/>} />
            <Route path='/webinars' element={<Webinars />} />
            <Route path='/webinars/:id' element={<WebinarDetail />} />
        </Routes>
        {!shouldHideHeaderFooter && <CTASection />}
        {!shouldHideHeaderFooter && <Footer />}
    </div>
  );
}

export default App;
