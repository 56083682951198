import React from "react";
import { useParams } from "react-router-dom";
import newsData from "../../assets/json/news.json";
import "./newsDetail.css";

function NewsDetail() {
  const { id } = useParams();
  const news = newsData.Data[id];

  if (!news) {
    return <p className="error-message">News article not found</p>;
  }

  return (
    <div className="news-detail-container">
      <div className="container">
        <div className="theme-title">
          <h2 className="news-title">{news.title}</h2>
        </div>
        <p className="news-author">By: {news.author}</p>
        <p className="news-content">{news.content}</p>
      </div>
    </div>
  );
}

export default NewsDetail;
