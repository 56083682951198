import React from "react";
import CTAData from "../../assets/json/CTAData.json";
import { useNavigate, useLocation } from "react-router-dom";

function CTASection() {
    const location = useLocation();
    const navigate = useNavigate();
    const isContactUs = location.pathname === "/contact";

    const handleClick = () => {
        {!isContactUs ? navigate('/contact') : window.scrollTo({ top: 0, behavior: 'smooth' })}
        
    };
    

    return (
        <section className="questions-section">
            <div className="container">
                <div className="row justify-content-between align-items-center position-relative">   
                    <div className="content">
                        <div className="cta-content">
                            <h2 className="text-black">
                                {CTAData?.title}
                            </h2>
                            <p className="mb-0 mt-2 text-gray">
                                {CTAData?.content}
                            </p>
                        </div>
                    </div>
                    <div className="questions-btn">
                        <button type="button" className="btn btn-outline-light ms-3" onClick={handleClick}>
                            {CTAData?.requestDemoBtn}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
    }

export default CTASection;