import {React, useState} from "react";
import { Link,useNavigate } from "react-router-dom";
import data from "../../assets/json/news.json";
import researchData from "../../assets/json/researchData.json";

import "./news.css";
import Common from "../Common";

function News() {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("--All Authors--");
  const cardsPerPage = 6;

  const navigate = useNavigate();

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  
  const items = data.Data
  ?.sort((a, b) => new Date(b.publishedDate) - new Date(a.publishedDate)) // Sorting by published date
  .map((news) => {

    return {
      author: news?.author,
      pubDate: news?.publishedDate
        ? new Date(news.publishedDate).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        : "",
      title: news?.title,
      link: `/news/${news.id || news.index}`, // Ensure unique links
      description: news?.content.substring(0, 100) + "...",
      categories: news?.categories?.map(category => category.name) || []
    };
  });

  const categories = ["--All Authors--", ...new Set(items?.flatMap(item => item.author))];

  const filteredItems = items?.filter(item => 
    (selectedCategory === "--All Authors--" || item.author.includes(selectedCategory)) &&
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const currentCards = filteredItems?.slice(indexOfFirstCard, indexOfLastCard);
  const totalPages = Math.ceil(filteredItems?.length / cardsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleClick = () => {
      navigate('/contact');
  };

  const handleCardClick = (index) => {
    navigate(`/news/${index}`);
  };

  return (
    <div className="news-page">
      <Common />
          <div className="news-card-section">
            <div className="container">
            <div className="blog-header-section">
            <div className="container">
              <div className="blog-search-header">
                <div className="row align-items-center">
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="theme-title">
                      <h3 className="title-left">
                        <span>Press Releases</span>
                      </h3>
                    </div>
                  </div>

                  <div className="col-lg-9 col-md-12 col-sm-12">
                    <div className="blog-inner-container">
                      <div className="blog-search">
                        <img
                          src={`${process.env.PUBLIC_URL}/image/${researchData?.images?.searchIconFileName}.svg`}
                          alt=""
                        />
                        <input
                          type="search"
                          placeholder="Search"
                          onChange={(e) => {setSearchTerm(e.target.value); setCurrentPage(1);}}
                        />
                      </div>
                      <div className="blog-dropdown">
                        <div className="dropdown">
                          <select
                            className="blog-btn w-100"
                            onChange={(e) => {setSelectedCategory(e.target.value); setCurrentPage(1);}}
                          >
                            {/* <option key={0} value="">
                              --All Topics--
                            </option> */}
                            {categories.map((ele, ind) => {
                              return (
                                <option key={ind} value={ele}>
                                  {ele}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
              </div>
            </div>
          </div>
          <div className="row">
            {currentCards.map((news, index) => (
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3" key={index}>
                <div 
                  className="news-card" 
                  onClick={() => handleCardClick(index)} 
                  style={{ cursor: 'pointer' }}
                >
                  <h3>{news.title}</h3>
                  <p>By: {news.author}</p>
                  <p>{news.pubDate}</p>
                  <p>{news.description}</p>
                  <Link to={`/news/${index}`}>
                    <button className="read-more-btn">Read More</button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="news-pagination">
              <nav aria-label="Page navigation example">
                {totalPages!==0 && (<ul className="pagination justify-content-center">
                  <li className={`page ${currentPage === 1 ? "disabled" : ""}`}>
                    <button
                      className="page-link me-4 previous-next-btn"
                      onClick={() => paginate(currentPage - 1)}
                    >
                      <img src="image/pagination-left-arrow.svg" alt="" />
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li
                      key={index}
                      className={`page ${
                        index + 1 === currentPage ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link ms-4 previous-next-btn"
                      onClick={() => paginate(currentPage + 1)}
                    >
                      Next
                      <img
                        src="image/pagination-right-arrow.svg"
                        alt=""
                        className=""
                      />
                    </button>
                  </li>
                </ul>)}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
