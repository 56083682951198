import solutionsPageData from "../../assets/json/solutionsData.json";
import homeSectionData from "../../assets/json/homePageData.json";
import SolutionsCard from "./SolutionsCard";
import Common from "../Common";
import { useState, useEffect, useRef } from "react";

function Solutions() {
    const tabs = solutionsPageData?.BenefitsSection?.tabs;
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [sliderStyle, setSliderStyle] = useState({ left: 0, width: 0 });
    const tabRefs = useRef([]);
    const currentTabData = solutionsPageData?.[activeTab]?.content;
    const benefitCardData = solutionsPageData?.BenefitsSection?.cardsData;
  
    useEffect(() => {
      const activeIndex = tabs.indexOf(activeTab);
      const activeTabElement = tabRefs.current[activeIndex];
      if (activeTabElement) {
        setSliderStyle({
          left: activeTabElement.offsetLeft,
          width: activeTabElement.offsetWidth,
        });
      }
    }, [activeTab, tabs]);

    return (
        <div>
            <Common />
            <section className="enterprises-database" id="product-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                            <div className="header-theme-title">
                                <h1>
                                    {solutionsPageData?.HeroSection?.title[0]}
                                    <span>{solutionsPageData?.HeroSection?.title[1]}</span>
                                    {solutionsPageData?.HeroSection?.title[2]}
                                </h1>
                                <p>{solutionsPageData?.HeroSection?.content}</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 leptop-layout">
                            <div className="solution-header-vectors">
                                {homeSectionData?.EnterprisesSection.boxImages.map(
                                    (ele, ind) => {
                                        return (
                                            <img
                                                id={ele.imageClass}
                                                className={ele.imageClass}
                                                src={`${process.env.PUBLIC_URL}/image/${ele.imageFileName}.svg`}
                                                alt=""
                                                key={ind}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        </div>
                        <div className="slider-optimized-content pb-5">
                            {benefitCardData.map((ele, index) => {
                                return (
                                    <div className="item-content" key={index}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/image/${ele.icon}.svg`}
                                            alt=""
                                        />
                                        <div className=".d-flex flex-column">
                                            <p className="fw-bold benefit-title">{ele.title}</p>
                                            <p>{ele.description}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <section className="benefits-section">
            </section>

            {/* Tab Section */}
            <section className="tab-section">
                <div className="container overflow-scroll">
                    <div className="tabs">
                    {tabs.map((tab, index) => (
                        <div
                        key={tab}
                        ref={(el) => (tabRefs.current[index] = el)}
                        className={`tab-item ${activeTab === tab ? "active" : ""}`}
                        onClick={() => setActiveTab(tab)}
                        >
                        {tab}
                        </div>
                    ))}
                    <div className="slider" style={{ left: sliderStyle.left, width: sliderStyle.width }} />
                    </div>
                </div>
            </section>
            <section className="tab-content-section mt-5">
                <div className="container text-center">
                    {/* Check if there's content for the active tab */}
                    {currentTabData && currentTabData.length > 0 ? (
                    <div className="row justify-content-center">
                        {currentTabData.map((cardItem, index) => (
                        <div className="col-md-6 d-flex mb-4" key={index}>
                            <SolutionsCard
                            icon={cardItem.icon}
                            title={cardItem.title}
                            description={cardItem.description}
                            points={cardItem.points}
                            />
                        </div>
                        ))}
                    </div>
                    ) : (
                    <p>Content coming soon...</p>
                    )}
                </div>
            </section>
        </div>
    );
}

export default Solutions;
